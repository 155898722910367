import { TableContext } from '../../App'
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material'
import { useContext } from 'react'

export function MainTableItems(props) {
  const { rows } = props

  const { selected, setSelected, openSnackbar } = useContext(TableContext)

  const isSelected = id => selected.indexOf(id) !== -1

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const copyItem = shortcut => event => {
    if (event.target.type === 'checkbox') return
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(`https://asri.pw/${shortcut}`).then(function () {
        openSnackbar('Copied')
      })
    }
  }

  return (
    <TableBody>
      {rows.map(item => {
        const isItemSelected = isSelected(item.ID)
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={item.ID}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
            onClick={copyItem(item.Shortcut)}
            hover
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                onClick={event => handleClick(event, item.ID)}
                disabled={item.DeletedAt.Valid}
              />
            </TableCell>
            <TableCell>{item.ID}</TableCell>
            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.Shortcut}
            </TableCell>
            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.URL}</TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
