import { TableContext, UrlContext } from '../../App'
import { MainTableHeader } from './MainTableHeader'
import { MainTableItems } from './MainTableItems'
import { Box, Table, TableContainer } from '@mui/material'
import { useContext, useMemo } from 'react'

export function MainTable() {
  const { urls } = useContext(UrlContext)

  const { selected, setSelected, ifShowDeleted } = useContext(TableContext)

  const items = useMemo(() => {
    return urls.filter(item => !item.DeletedAt.Valid || ifShowDeleted).sort((a, b) => b.ID - a.ID)
  }, [urls, ifShowDeleted])

  const handleSelectAllClick = () => {
    if (selected.length < items.length) {
      const newSelected = urls.filter(item => !item.DeletedAt.Valid).map(item => item.ID)
      setSelected(newSelected)
    } else {
      setSelected([])
    }
  }

  return (
    <Box sx={{ p: 4, flex: 1, overflow: 'auto' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <TableContainer
          sx={{
            height: '100%',
            border: 1,
            borderRadius: '4px',
            borderColor: 'rgba(224, 224, 224, 1)'
          }}
        >
          <Table stickyHeader sx={{ minWidth: '1000px', tableLayout: 'fixed' }}>
            <MainTableHeader onSelectAllClick={handleSelectAllClick} rowCount={items.length} />
            <MainTableItems rows={items} />
          </Table>
        </TableContainer>
        {/* <DataGrid
          rows={urls.filter((item) => !item.DeletedAt.Valid).sort(((a, b) => b.ID - a.ID))}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[50, 100]}
          checkboxSelection
          getRowId={(item) => item.ID}
        /> */}
      </Box>
    </Box>
  )
}
