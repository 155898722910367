import './App.css'
import { MainTable } from './components/MainTable/MainTable'
import { SideEditor } from './components/SideEditor'
import { InitAPI } from './lib/api'
import { Box, Snackbar } from '@mui/material'
import { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext({})
export const UrlContext = createContext({})
export const TableContext = createContext({})

function App() {
  const [urls, fetchData, postShortcut, deleteShortcuts] = InitAPI()

  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')

  const [selected, setSelected] = useState([])
  const [ifShowDeleted, setIfShowDeleted] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  useEffect(() => {
    let tempUser, tempPass
    document.cookie.split(/;\s*/).forEach(row => {
      const [k, v] = row.split('=')
      if (k === 'shurl-user') tempUser = v.replace(/["]/g, '')
      if (k === 'shurl-pass') tempPass = v.replace(/["]/g, '')
    })
    setUser(tempUser)
    setPass(tempPass)
    if (tempUser && tempPass) {
      fetchData(tempUser, tempPass)
    }
  }, [])

  const setSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const openSnackbar = message => {
    setSnackbarMessage(message)
    setSnackbarOpen(true)
  }

  return (
    <div className="App">
      <AuthContext.Provider value={{ user, setUser, pass, setPass }}>
        <UrlContext.Provider value={{ urls, fetchData, postShortcut, deleteShortcuts }}>
          <TableContext.Provider
            value={{
              selected,
              setSelected,
              ifShowDeleted,
              setIfShowDeleted,
              openSnackbar
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                maxWidth: '1120px',
                mx: 'auto',
                display: 'flex'
              }}
            >
              <SideEditor />
              <MainTable />
            </Box>
          </TableContext.Provider>
        </UrlContext.Provider>
      </AuthContext.Provider>

      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={setSnackbarClose}
      />
    </div>
  )
}

export default App
