import { AuthContext, TableContext, UrlContext } from '../App'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material'
import { useContext, useState } from 'react'

export function SideEditor() {
  const { fetchData, postShortcut, deleteShortcuts } = useContext(UrlContext)
  const { selected, setSelected, setIfShowDeleted, openSnackbar } = useContext(TableContext)
  const { user, setUser, pass, setPass } = useContext(AuthContext)

  const [newShortcut, setNewShortcut] = useState('')
  const [newURL, setNewURL] = useState('')

  const handleClickFetch = () => {
    document.cookie = `shurl-user="${user}"; max-age=3600`
    document.cookie = `shurl-pass="${pass}"; max-age=3600`
    fetchData(user, pass)
  }

  const handlePost = () => {
    postShortcut(newShortcut, newURL, user, pass)
  }

  const deleteSelections = () => {
    if (selected.length === 0) return
    deleteShortcuts(selected, user, pass)
    setSelected([])
    openSnackbar('Deleted')
  }

  return (
    <Box
      sx={{
        width: '30%',
        maxWidth: '240px',
        p: 4,
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Box textAlign="right">
        <Divider>
          <Typography variant="h6">Authentication</Typography>
        </Divider>
        <TextField
          label="User"
          margin="dense"
          size="small"
          value={user}
          onChange={event => {
            setUser(event.target.value)
          }}
          sx={{ width: '100%' }}
        />
        <TextField
          label="Password"
          type="password"
          autoComplete="current-password"
          margin="dense"
          size="small"
          value={pass}
          onChange={event => {
            setPass(event.target.value)
          }}
          sx={{ width: '100%' }}
        />
        <Button variant="outlined" sx={{ my: 1 }} onClick={handleClickFetch}>
          Fetch
        </Button>
      </Box>

      <Box textAlign="right">
        <Divider>
          <Typography variant="h6">New</Typography>
        </Divider>
        <TextField
          label="Shortcut"
          margin="dense"
          size="small"
          value={newShortcut}
          onChange={event => {
            setNewShortcut(event.target.value)
          }}
          sx={{ width: '100%' }}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="URL"
          multiline
          maxRows={6}
          margin="dense"
          size="small"
          value={newURL}
          onChange={event => {
            setNewURL(event.target.value)
          }}
          sx={{ width: '100%' }}
        />
        <Button variant="outlined" sx={{ my: 1 }} onClick={handlePost}>
          Add
        </Button>
      </Box>

      <Box>
        <Divider>
          <Typography variant="h6">Filter</Typography>
        </Divider>
        <TextField
          label="Search (not implemented)"
          margin="dense"
          size="small"
          sx={{ width: '100%' }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Show deleted"
          onChange={event => {
            setIfShowDeleted(event.target.checked)
          }}
        />
        <Box textAlign="right">
          <Button variant="outlined" color="error" onClick={deleteSelections}>
            Delete selections
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
